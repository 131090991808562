import React from "react";
import { Link } from "react-router-dom";
import Query from "../../utils/Query";
import { GET_HOME_HEADER } from "../../queries/pageHeaders/getHomeHeader";
import HeroSkeleton from "../HeroComponent/HeroSkeleton";

function HeroSection() {
  return (
    <Query query={GET_HOME_HEADER} loader={<HeroSkeleton />}>
      {({
        data: {
          page: { homeHeader },
        },
      }) => {
        return (
          <section role="hero">
            <video className="videoBox" autoPlay={true} loop muted={true}>
              <source
                src={homeHeader.backgroundvideo.mediaItemUrl}
                type="video/mp4"
              />
            </video>

            <section className="heroInfo">
              <img
                src={homeHeader.headerimage.sourceUrl}
                srcSet={homeHeader.headerimage.srcSet}
                alt=""
              />

              <article className="ctaContent">
                <h1 className="strongUp text-center cpr home_main_h1">
                  {homeHeader.headertitle}
                </h1>
                <p>{homeHeader.headerdescription}</p>

                <a
                  href={"https://shop.paicho.com/"}
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  <button className="bton bton--lg bton--primary">
                    Shop Now
                  </button>
                </a>

                <Link to="/company-overview">
                  <button className="bton bton--ghost bton--lg">
                    Discover
                  </button>
                </Link>
              </article>
            </section>
          </section>
        );
      }}
    </Query>
  );
}

export default HeroSection;
