import React from "react";
import { Col } from "react-bootstrap";
import Skeleton from "../common/Skeleton";

const ContentSkeleton = () => {
  return (
    <Col lg={6}>
      <div className="skeleton-header">
        <Skeleton height="2rem" width="100%" margin="0 0 1rem 0" />
      </div>
      <div className="skeleton-content">
        <Skeleton height="2rem" width="100%" margin="0 0 1.5rem 0" />
      </div>
      <div className="skeleton-para">
        <Skeleton height="1.2rem" width="100%" />
        <Skeleton height="1.2rem" width="98%" />
        <Skeleton height="1.2rem" width="96%" />
        <Skeleton height="1.2rem" width="99%" />
        <Skeleton height="1.2rem" width="90%" />
        <Skeleton height="1.2rem" width="50%" />
        <Skeleton height="1.2rem" width="100%" />
        <Skeleton height="1.2rem" width="98%" />
        <Skeleton height="1.2rem" width="99%" />
        <Skeleton height="1.2rem" width="60%" />
      </div>
    </Col>
  );
};

export default ContentSkeleton;
