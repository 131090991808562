import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { GET_STATISTICS } from '../../queries/statistics/getStatistics';
import Query from '../../utils/Query';
import StatSkeleton from './StatSkeleton';

function Stats() {
  return (
    <section className='statsWrapper'>
      <Container>
        <Row>
          <Query query={GET_STATISTICS} loader={<StatSkeleton />}>
            {({
              data: {
                page: {
                  statistics: { statisticsItems },
                },
              },
            }) => {
              return statisticsItems?.map((entry, key) => (
                <Col md={3} key={key}>
                  <section className='stats'>
                    <div className='stats__number'> {entry.statValue}</div>
                    <div className='stats__title'>{entry.statName}</div>
                  </section>
                </Col>
              ));
            }}
          </Query>
        </Row>
      </Container>
    </section>
  );
}

export default Stats;
