import React from "react";

import { Col, Container, Row } from "react-bootstrap";
import HeroComponent from "../../components/HeroComponent";
import ContactForm from "../../components/contact/ContactForm";
import Map from "../../components/contact/Map";
import { GET_CONTACT_HEADER } from "../../queries/pageHeaders/getContactHeader";
import HeroSkeleton from "../../components/HeroComponent/HeroSkeleton";
import Query from "../../utils/Query";

const Contact = () => {
  return (
    <div>
      <Query query={GET_CONTACT_HEADER} loader={<HeroSkeleton />}>
        {({ data: { page } }) => {
          return (
            <HeroComponent
              image={page.pageHeaderBlock.pageBackground.sourceUrl}
              title={page.pageHeaderBlock.pageTitle}
              content={page.pageHeaderBlock.pageDescription}
            />
          );
        }}
      </Query>

      <section className="contactWrapper">
        <Container>
          <Row>
            <Col lg={6}>
              <Map />
            </Col>
            <Col lg={6}>
              <ContactForm />
            </Col>
          </Row>
        </Container>
      </section>
    </div>
  );
};

export default Contact;
