import React from "react";
import { Route, Routes } from "react-router-dom";

//components

import BoardProfile from "../BoardProfile";
import CompanyOverview from "../CompanyOverview";
import Contact from "../Contact";
import Factory from "../Factory";
import Gallery from "../Gallery";

//pages

import Home from "../Home";
import News from "../News";
import Outlets from "../Outlets";
import Products from "../Products";
import Layout from "../../components/common/Layout";

const App = () => {
  return (
    <Layout>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/products" element={<Products />} />
        <Route path="/company-overview" element={<CompanyOverview />} />
        <Route path="/board-profile" element={<BoardProfile />} />
        <Route path="/outlets" element={<Outlets />} />
        <Route path="/gallery" element={<Gallery />} />
        <Route path="/factory" element={<Factory />} />
        <Route path="/news" element={<News />} />
        <Route path="/contact" element={<Contact />} />
        {/* <Route path="/teams" element={<Teams />} /> */}
      </Routes>
    </Layout>
  );
};

export default App;
