import { gql } from "@apollo/client";

export const GET_TESTIMONIALS = gql`
  query getTestimonials {
    testimonials(first: 1000) {
      nodes {
        testimonials {
          name
          designation
          message
        }
      }
    }
  }
`;
