import { gql } from "@apollo/client";

export const GET_GALLERY = gql`
  query getGallery {
    page(id: "cG9zdDo0MDcy", idType: ID) {
      pageHeaderBlock {
        pageTitle
        pageDescription
        pageBackground {
          sourceUrl
          srcSet
        }
      }
      gallery {
        images {
          sourceUrl
          srcSet
        }
        videos {
          youtubeUrl
        }
      }
    }
  }
`;
