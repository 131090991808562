import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import ISOImage2015 from '../../assets/Images/staticImages/iso-9001-2015.jpg';
import ISOImage22000 from '../../assets/Images/staticImages/ISO22000.png';

const ISOCertified = () => {
  return (
    <div>
      <Container>
        <section className='isoWrapper'>
          <div className='preTitle'>certification</div>
          <h2 className='mainTitle'>ISO 9001 Certified</h2>

          <Row>
            <Col md={6} xs={12}>
              <p className='isoWrapper-isoContent'>
                PAICHO PASAL aims to enhance customer satisfaction through the
                effective application of the system, including processes for
                improvement of the system and the assurance of conformity to
                customer and applicable statutory and regulatory requirements.It
                is ISO 9001:2015 and ISO 22000:2018 certified.
              </p>
            </Col>
            <Col md={3}>
              <figure className='isoWrapper-image'>
                <img src={ISOImage2015} alt='' />
              </figure>
            </Col>
            <Col md={3}>
              <figure className='isoWrapper-image'>
                <img src={ISOImage22000} alt='' />
              </figure>
            </Col>
          </Row>
        </section>
      </Container>
    </div>
  );
};

export default ISOCertified;
