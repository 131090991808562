import React, { useEffect, useState } from "react";
// import { FaArrowCircleUp } from 'react-icons/fa';

const ScrollButton = () => {
  const [visible, setVisible] = useState(false);

  const toggleVisible = () => {
    const scrolled = document.documentElement.scrollTop;
    if (scrolled > 500) {
      setVisible(true);
    } else if (scrolled <= 500) {
      setVisible(false);
    }
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
      /* you can also use 'auto' behaviour
		in place of 'smooth' */
    });
  };

  useEffect(() => {
    window.addEventListener("scroll", toggleVisible);

    return () => {
      window.removeEventListener("scroll", toggleVisible);
    };
  }, []);
  return (
    <div>
      <div
        onClick={scrollToTop}
        // style={{ display: visible ? 'inline' : 'none' }}
        className={visible ? "scrolltop" : "notVisible"}
      >
        <i className="fa  fa-long-arrow-up"></i>
      </div>
    </div>
  );
};

export default ScrollButton;
