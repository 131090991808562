import React, { useState } from "react";
import HeroComponent from "../../components/HeroComponent";
import { Tab, Col, Container, Row, Nav } from "react-bootstrap";

import MediaCard from "../../components/MediaCard";
import MediaCardSkeleton from "../../components/MediaCard/MediaCardSkeleton";
import { GET_GALLERY } from "../../queries/gallery/getGallery";
import Query from "../../utils/Query";

const Gallery = () => {
  const [mediaItem, setMediaItem] = useState("All Media");

  return (
    <Query query={GET_GALLERY} loader={<MediaCardSkeleton />}>
      {({ data: { page } }) => {
        const images = page.gallery.images.map((entry) => (
          <MediaCard
            key={entry.sourceUrl}
            image={entry.sourceUrl}
            category="image"
            link="#"
          />
        ));

        const videos = page.gallery.videos.map(({ youtubeUrl }, key) => (
          <MediaCard key={key} image="" category="video" link={youtubeUrl} />
        ));

        const all = [...images, ...videos];

        return (
          <>
            <HeroComponent
              image={page.pageHeaderBlock.pageBackground.sourceUrl}
              title={page.pageHeaderBlock.pageTitle}
              content={page.pageHeaderBlock.pageDescription}
            />

            <Container>
              <section className="galleryWrapper">
                <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                  <Row>
                    <Col>
                      <Nav variant="pills " className="flex-row">
                        <Nav.Item>
                          <Nav.Link
                            eventKey="first"
                            onClick={(e) => {
                              e.preventDefault();
                              setMediaItem("All Media");
                            }}
                          >
                            All
                          </Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                          <Nav.Link
                            eventKey="second"
                            onClick={(e) => {
                              e.preventDefault();
                              setMediaItem("image");
                            }}
                          >
                            Images
                          </Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                          <Nav.Link
                            eventKey="third"
                            onClick={(e) => {
                              e.preventDefault();
                              setMediaItem("video");
                            }}
                          >
                            Videos
                          </Nav.Link>
                        </Nav.Item>
                      </Nav>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <section>
                        <Row>
                          {mediaItem === "All Media"
                            ? all
                            : mediaItem === "image"
                            ? images
                            : videos}
                        </Row>
                      </section>
                    </Col>
                  </Row>
                </Tab.Container>
              </section>
            </Container>
          </>
        );
      }}
    </Query>
  );
};

export default Gallery;
