import { gql } from "@apollo/client";

export const GET_OUTLETS = gql`
  query getOutlets {
    outlets(first: 1000) {
      nodes {
        slug
        title
        stores {
          title
          featuredImage {
            sourceUrl
            srcSet
          }
          storeDetails {
            storeName
            storeManager
            phone
            address
          }
        }
      }
    }
  }
`;
