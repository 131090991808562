import React from "react";
import { Col } from "react-bootstrap";

function OutletCard({ title, name, phone, location }) {
  return (
    <Col md={6} lg={4}>
      <div className="mb-4">
        <div className="outletCard">
          <div className="outletCard-title">{title}</div>
          <div className="outletCard-info">
            <i className="fa fa-user"></i> <span>{name}</span>
          </div>
          <div className="outletCard-info">
            <i className="fa fa-phone"></i> <span>{phone}</span>
          </div>
          <div className="outletCard-info">
            <i
              className="fa  fa-map-marker
          "
            ></i>{" "}
            <span>{location}</span>
          </div>
        </div>
      </div>
    </Col>
  );
}

export default OutletCard;
