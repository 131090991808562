import React from "react";
import { Carousel, Container } from "react-bootstrap";

import Quotation from "../../assets/Images/quotation.svg";
import Avatar from "../../assets/Images/avatar.png";
import TestimonialSkeleton from "./TestimonialSkeleton";
import Query from "../../utils/Query";
import { GET_TESTIMONIALS } from "../../queries/testimonials/getTestimonials";

function Testimonials() {
  return (
    <section className="testimonials">
      <Container>
        <div className="preTitle">Testimonials</div>
        <h2 className="mainTitle">What People Say About Paicho</h2>

        <section className="mt-5">
          {/* <TestimonialSkeleton /> */}
          <Query query={GET_TESTIMONIALS} loader={<TestimonialSkeleton />}>
            {({
              data: {
                testimonials: { nodes },
              },
            }) => {
              return (
                <Carousel variant="dark">
                  {nodes.map(({ testimonials }, key) => (
                    <Carousel.Item key={key}>
                      <section className="testiCard">
                        <div className="testiCard__icon">
                          <img src={Quotation} alt="" />
                        </div>
                        <div
                          className="testiCard__text"
                          dangerouslySetInnerHTML={{
                            __html: testimonials.message,
                          }}
                        />

                        <section className="testiCard__avatar">
                          <img src={Avatar} alt="" />
                          <section>
                            <div className="testiCard__avatar--name">
                              {testimonials.name}
                            </div>
                            <div className="testiCard__avatar--post">
                              {testimonials.designation}
                            </div>
                          </section>
                        </section>
                      </section>
                    </Carousel.Item>
                  ))}
                </Carousel>
              );
            }}
          </Query>
        </section>
      </Container>
    </section>
  );
}
export default Testimonials;
