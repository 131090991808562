import React from "react";
import { Container, Row } from "react-bootstrap";
import BoardImageSkeleton from "../boardProfile/BoardImageSkeleton";
import BoardMessageSkeleton from "../boardProfile/BoardMessageSkeleton";

const MessageSkeleton = () => {
  return (
    <Container>
      <Row>
        <BoardImageSkeleton />
        <BoardMessageSkeleton />
      </Row>
    </Container>
  );
};

export default MessageSkeleton;
