import { gql } from "@apollo/client";

export const GET_BOARD_PROFILES = gql`
  query getBoardProfiles {
    boardProfiles(first: 1000) {
      nodes {
        boardProfile {
          name
          message
          designation
          photo {
            sourceUrl
            srcSet
          }
        }
      }
    }
  }
`;
