import React from "react";
import { Col } from "react-bootstrap";
import Skeleton from "../common/Skeleton";

const OutletCardSkeleton = () => {
  return (
    <div className="row">
      {[...Array(7)].map((_, key) => (
        <Col md={6} lg={4} key={key}>
          <div className="mb-4">
            <div className="outletCard">
              <Skeleton height="3rem" width="100%" radius="0" />
              <div className="outletCard-info d-flex">
                <i className="fa fa-user"></i>
                <Skeleton height="1.5rem" width="90%" radius="0" />
              </div>
              <div className="outletCard-info d-flex">
                <i className="fa fa-phone"></i>
                <Skeleton height="1.5rem" width="60%" radius="0" />
              </div>
              <div className="outletCard-info d-flex">
                <i
                  className="fa  fa-map-marker
                "
                ></i>
                <Skeleton height="1.5rem" width="75%" radius="0" />
              </div>
            </div>
          </div>
        </Col>
      ))}
    </div>
  );
};

export default OutletCardSkeleton;
