import React from "react";
import { Col } from "react-bootstrap";
import Skeleton from "../common/Skeleton";

const CardSkeleton = () => {
  return (
    <Col xlg={3} lg={4} md={6}>
      <div className="skeleton-card">
        <Skeleton height="18rem" width="100%" />
        <Skeleton height="1.5rem" width="50%" />
        <Skeleton height="3rem" width="100%" />
      </div>
    </Col>
  );
};

export default CardSkeleton;
