import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import HeroComponent from "../../components/HeroComponent";
import FactorySkeleton from "../../components/factory/FactorySkeleton";
import Query from "../../utils/Query";
import { GET_FACTORY_PAGE } from "../../queries/factory/getFactoryPage";

const Factory = () => {
  return (
    <Query query={GET_FACTORY_PAGE} loader={<FactorySkeleton />}>
      {({ data: { page } }) => {
        return (
          <section>
            <HeroComponent
              image={page.pageHeaderBlock.pageBackground.sourceUrl}
              title={page.pageHeaderBlock.pageTitle}
              content={page.pageHeaderBlock.pageDescription}
            />

            <Container className="factoryContainer">
              <section className="factoryWrapper">
                <section className="factoryProducts">
                  <div className="preTitle">{page.factory.smallHeading}</div>
                  <h2 className="mainTitle">{page.factory.bigHeading}</h2>
                  <img
                    src={page.factory.featuredImage.sourceUrl}
                    srcSet={page.factory.featuredImage.srcSet}
                    alt=""
                  />
                </section>
                <section className="processWrapper">
                  <div className="preTitle">How things work</div>
                  <h2 className="mainTitle"> Factory Mechanism</h2>
                  {page.factory.mechanism.map((entry, key) => (
                    <div className="factDetails" key={key}>
                      <Row>
                        <Col md={6}>
                          <div className="factDetails-image">
                            <img
                              src={entry.image.sourceUrl}
                              srcSet={entry.image.srcSet}
                              alt=""
                            />
                          </div>
                        </Col>
                        <Col md={6}>
                          <div className="factDetails_title">
                            <h2>{entry.title}</h2>
                          </div>
                          <div
                            className="factDetails-desc"
                            dangerouslySetInnerHTML={{
                              __html: entry.description,
                            }}
                          />
                        </Col>
                      </Row>
                    </div>
                  ))}
                </section>
              </section>
            </Container>
          </section>
        );
      }}
    </Query>
  );
};

export default Factory;
