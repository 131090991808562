import React from "react";
import { Col, Row } from "react-bootstrap";

import LayoutImage from "../../assets/Images/staticImages/for natiion.jpg";

function Contribution() {
  return (
    <section className="layoutTwo">
      <section className="layoutWrapper">
        <Row>
          <Col lg={6}>
            <section className="whyContentWrapper">
              <section className="whyContent">
                <div className="preTitle">Agriculture and support</div>
                <h2 className="mainTitle">Paicho's Contribution For Nation</h2>

                <p>
                  Agriculture is the major sector of Nepalese economy. employing
                  over 66% of the country’s labor force and providing over
                  one-third of the country’s gross domestic products. Paicho
                  contributes to enhancing the quality of, and the demand for,
                  farm product having the potential to provide employment for
                  the rural population not only in farming, but also in off-farm
                  activities such as handling, packaging, processing and
                  agricultural products.
                  <br />
                  <br />
                  We build the capacity of farmers by training them and offering
                  complete technical services including after sales service,
                  progress monitoring, market linkages and business solutions to
                  aspiring farmers &amp; enterprenuers.
                </p>
              </section>

              <section className="whyContentCopy">
                <div className="preTitle">Agriculture and support</div>
                <h2 className="mainTitle">Paicho's Contribution For Nation</h2>

                <p>
                  Agriculture is the major sector of Nepalese economy. employing
                  over 66% of the country’s labor force and providing over
                  one-third of the country’s gross domestic products. Paicho
                  contributes to enhancing the quality of, and the demand for,
                  farm product having the potential to provide employment for
                  the rural population not only in farming, but also in off-farm
                  activities such as handling, packaging, processing and
                  agricultural products.
                  <br />
                  <br />
                  We build the capacity of farmers by training them and offering
                  complete technical services including after sales service,
                  progress monitoring, market linkages and business solutions to
                  aspiring farmers &amp; enterprenuers.
                </p>
              </section>
            </section>
          </Col>
          <Col lg={6} className=" order-lg-last order-first">
            <section className="whyImage">
              <img src={LayoutImage} alt="" />
            </section>
          </Col>
        </Row>
      </section>
    </section>
  );
}

export default Contribution;
