import React from "react";
import Skeleton from "../common/Skeleton";

const ListSkeleton = () => {
  return (
    <div className="d-flex gap-3 flex-wrap">
      {[...Array(7)].map((_, key) => (
        <Skeleton height="3.2rem" width="8rem" radius="30px" key={key} />
      ))}
    </div>
  );
};

export default ListSkeleton;
