import React from "react";

const Skeleton = ({ height, width, margin, radius = "4px" }) => {
  return (
    <div
      className="skeleton-box"
      style={{
        height: `${height}`,
        width: `${width}`,
        margin: `${margin}`,
        borderRadius: `${radius}`,
      }}
    ></div>
  );
};

export default Skeleton;
