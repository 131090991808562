import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import Skeleton from "../common/Skeleton";
import HeroSkeleton from "../HeroComponent/HeroSkeleton";

const FactorySkeleton = () => {
  return (
    <section>
      <HeroSkeleton />
      <Container className="factoryContainer">
        <section className="factoryWrapper">
          <section className="factoryProducts">
            <div className="skeleton-header mb-2">
              <Skeleton height="2rem" width="90%" />
            </div>
            <div className="skeleton-header mb-4">
              <Skeleton height="3rem" width="100%" />
            </div>
            <Skeleton height="15rem" width="100%" />
          </section>

          <section className="processWrapper">
            <div className="skeleton-header mb-2">
              <Skeleton height="2rem" width="90%" />
            </div>
            <div className="skeleton-header">
              <Skeleton height="3rem" width="100%" />
            </div>

            <section className="mt-5">
              <Row className="mb-5">
                <Col md={6} className="mb-4">
                  <Skeleton height="20rem" width="100%" />
                </Col>
                <Col md={6}>
                  <div className="skeleton-header mb-4">
                    <Skeleton height="3rem" width="100%" />
                  </div>
                  <div className="d-flex flex-column gap-3">
                    {[...Array(6)].map((_, key) => (
                      <div className="skeleton-content" key={key}>
                        <Skeleton height="1.2rem" width="100%" />
                      </div>
                    ))}
                  </div>
                </Col>
              </Row>
              <Row className="mb-5">
                <Col md={6} className="mb-4">
                  <div className="skeleton-header mb-4">
                    <Skeleton height="3rem" width="100%" />
                  </div>
                  <div className="d-flex flex-column gap-3">
                    {[...Array(6)].map((_, key) => (
                      <div className="skeleton-content" key={key}>
                        <Skeleton height="1.2rem" width="100%" />
                      </div>
                    ))}
                  </div>
                </Col>
                <Col md={6}>
                  <Skeleton height="20rem" width="100%" />
                </Col>
              </Row>
            </section>
          </section>
        </section>
      </Container>
    </section>
  );
};

export default FactorySkeleton;
