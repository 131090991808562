import React from 'react';
import { Container } from 'react-bootstrap';

function Welcome() {
  return (
    <Container>
      <section className='text-center ' style={{ margin: '5rem 0' }}>
        <h2 className='mainTitle mb-3'>Welcome to Paicho Company!</h2>
        <p className='text-center w-100 '>
          At Paicho Company, we transform high-altitude vegetables into
          delightful products like tomato ketchup, pickles, and jams. Our
          mission goes beyond delivering quality food; we’re dedicated to
          fostering the economic growth of our farmers. By guiding them through
          every stage—from planting to harvest—and utilizing their produce
          efficiently, we create value and support their prosperity. Nestled in
          the heart of the hilly regions, we blend cutting-edge technology with
          traditional farming wisdom to ensure excellence in every product. Join
          us in celebrating the journey from seed to shelf, where each product
          reflects our commitment to quality and community growth.
        </p>
      </section>
    </Container>
  );
}

export default Welcome;
