import React from "react";
import { Col } from "react-bootstrap";

function MediaCard({ image, link, category }) {
  return (
    <Col md={4}>
      {category === "video" ? (
        <section className="mediaWrapper">
          <iframe
            src={link}
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>
        </section>
      ) : (
        <section className="mediaWrapper">
          <img src={image} alt="" />
        </section>
      )}
    </Col>
  );
}

export default MediaCard;
