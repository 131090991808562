import React from "react";
import Skeleton from "../common/Skeleton";

const AwardsSkeleton = () => {
  return (
    <div className="row">
      {[...Array(4)].map((_, key) => (
        <div key={key} className="col-md-6 mb-4 skeleton-award">
          <Skeleton height="100%" width="100%" margin="auto" />
        </div>
      ))}
    </div>
  );
};

export default AwardsSkeleton;
