import React, { useState } from "react";
import { Tab, Col, Container, Row, Nav } from "react-bootstrap";

import HeroComponent from "../../components/HeroComponent";

import CategoryCard from "../../components/CategoryCard";
import HeroSkeleton from "../../components/HeroComponent/HeroSkeleton";
import ListSkeleton from "../../components/products/ListSkeleton";
import Query from "../../utils/Query";
import { GET_PRODUCTS_HEADER } from "../../queries/pageHeaders/getProductHeader";
import { GET_ALL_PRODUCTS } from "../../queries/categories/getAllProducts";

const Products = () => {
  const [name, setName] = useState(-1);
  return (
    <>
      <Query query={GET_PRODUCTS_HEADER} loader={<HeroSkeleton />}>
        {({ data: { page } }) => {
          return (
            <HeroComponent
              image={page.pageHeaderBlock.pageBackground.sourceUrl}
              title={page.pageHeaderBlock.pageTitle}
              content={page.pageHeaderBlock.pageDescription}
            />
          );
        }}
      </Query>

      <section className="productsWrapper">
        <Container>
          <section className="mt-5">
            <Query query={GET_ALL_PRODUCTS} loader={<ListSkeleton />}>
              {({
                data: {
                  categories: { nodes },
                },
              }) => {
                return (
                  <Tab.Container
                    id="left-tabs-example"
                    defaultActiveKey="first"
                  >
                    <Row>
                      <Col>
                        <Nav variant="pills " className="flex-row">
                          <Nav.Item>
                            <Nav.Link
                              eventKey="first"
                              onClick={(e) => {
                                e.preventDefault();
                                setName(-1);
                              }}
                            >
                              All Products
                            </Nav.Link>
                          </Nav.Item>
                          {nodes.map((entry, key) => (
                            <Nav.Item>
                              <Nav.Link
                                eventKey={entry.slug}
                                onClick={(e) => {
                                  e.preventDefault();
                                  setName(key);
                                }}
                              >
                                {entry.category.title}
                              </Nav.Link>
                            </Nav.Item>
                          ))}
                        </Nav>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Row>
                          {name < 0
                            ? nodes.map((e) =>
                                e.category.products.map((entry, key) => (
                                  <CategoryCard
                                    key={key}
                                    link={entry.productUrl}
                                    name={entry.productName}
                                    image={entry.productImage}
                                  />
                                ))
                              )
                            : nodes[name].category.products.map(
                                (entry, key) => (
                                  <CategoryCard
                                    key={key}
                                    link={entry.productUrl}
                                    name={entry.productName}
                                    image={entry.productImage}
                                  />
                                )
                              )}
                          {/* {name === "All Products"
                            ? products.map((p) => {
                                return (
                                  <CategoryCard
                                    link="https://shop.paicho.com/"
                                    name={p.name}
                                    image={p.image}
                                  />
                                );
                              })
                            : products
                                .filter((p) => p.category === name)
                                .map((p) => {
                                  return (
                                    <CategoryCard
                                      link="https://shop.paicho.com/"
                                      name={p.name}
                                      image={p.image}
                                    />
                                  );
                                })} */}
                        </Row>
                      </Col>
                    </Row>
                  </Tab.Container>
                );
              }}
            </Query>
          </section>
        </Container>
      </section>
    </>
  );
};

export default Products;
