import React from "react";
import HeroSkeleton from "../HeroComponent/HeroSkeleton";
import StatSkeleton from "../Stats/StatSkeleton";
import BackgroundSkeleton from "./BackgroundSkeleton";
import MessageSkeleton from "./MessageSkeleton";
import MissonSkeleton from "./MissonSkeleton";

const CompanyOverviewSkeleton = () => {
  return (
    <div className="d-flex flex-column gap-5">
      <HeroSkeleton />
      <BackgroundSkeleton />
      <MissonSkeleton />
      <StatSkeleton />
      <MessageSkeleton />
    </div>
  );
};

export default CompanyOverviewSkeleton;
