import React, { useState } from "react";
import { CloseButton, Dropdown } from "react-bootstrap";
import { Link, NavLink } from "react-router-dom";

//image here

import BrandImage from "../../assets/Images/logo-small.png";

const Navbar = () => {
  const [navInfo, setNavInfo] = useState(false);
  return (
    <div className="navMain">
      <nav className="container navWrapper">
        <figure className="navWrapper__navBrand">
          <Link to="/">
            <img src={BrandImage} alt="" />
          </Link>
        </figure>

        <ul className="navWrapper__navItems">
          <li className="navWrapper__navItems--items">
            <NavLink
              className={(navData) =>
                navData.isActive ? "activeSidebar" : "notActive"
              }
              to="/"
            >
              Home
            </NavLink>
          </li>
          <li className="navWrapper__navItems--items">
            <NavLink
              className={(navData) =>
                navData.isActive ? "activeSidebar" : "notActive"
              }
              to="/products"
            >
              Products
            </NavLink>
          </li>
          <li className="navWrapper__navItems--items">
            <NavLink
              className={(navData) =>
                navData.isActive ? "activeSidebar" : "notActive"
              }
              to="/gallery"
            >
              Gallery
            </NavLink>
          </li>
          <li className="navWrapper__navItems--items">
            <NavLink
              className={(navData) =>
                navData.isActive ? "activeSidebar" : "notActive"
              }
              to="/outlets"
            >
              Outlets
            </NavLink>
          </li>
          <li className="navWrapper__navItems--items">
            <NavLink
              className={(navData) =>
                navData.isActive ? "activeSidebar" : "notActive"
              }
              to="/factory"
            >
              Factory
            </NavLink>
          </li>

          <li className="navWrapper__navItems--items">
            <section role="navDrop">
              <Dropdown>
                <Dropdown.Toggle variant="success" id="dropdown-basic">
                  About Us
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <Dropdown.Item>
                    <NavLink
                      className={(navData) =>
                        navData.isActive ? "activeSidebar" : "notActive"
                      }
                      to="/company-overview"
                    >
                      Company Overview
                    </NavLink>
                  </Dropdown.Item>
                  <Dropdown.Item>
                    <NavLink
                      className={(navData) =>
                        navData.isActive ? "activeSidebar" : "notActive"
                      }
                      to="/board-profile"
                    >
                      Board Profile
                    </NavLink>
                  </Dropdown.Item>
                  <Dropdown.Item>
                    <NavLink
                      className={(navData) =>
                        navData.isActive ? "activeSidebar" : "notActive"
                      }
                      to="/news"
                    >
                      Paicho On Media
                    </NavLink>
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </section>
          </li>
          <li className="navWrapper__navItems--items">
            <NavLink
              className={(navData) =>
                navData.isActive ? "activeSidebar" : "notActive"
              }
              to="/contact"
            >
              Contact
            </NavLink>
          </li>
        </ul>

        <section className="navWrapper__mdNav">
          <section className="nav_mdInfo">
            {
              <button className="open_mdNav" onClick={() => setNavInfo(true)}>
                &#9776;
              </button>
            }
          </section>
          {
            <section
              className={`mdNav_list_container ${navInfo && " showmdNav"}`}
            >
              <ul className="mdNav_list">
                <li>
                  <figure className="mdnavBrand">
                    <Link to="/">
                      <img src={BrandImage} alt="" />
                    </Link>
                  </figure>
                  <div className="close_mdNav_container">
                    <div
                      className="close_mdNav"
                      onClick={() => setNavInfo(false)}
                    >
                      <CloseButton />
                    </div>
                  </div>
                </li>

                <li
                  className="navWrapper__navItems--items"
                  onClick={() => setNavInfo(false)}
                >
                  <NavLink
                    className={(navData) =>
                      navData.isActive ? "activeSidebar" : "notActive"
                    }
                    to="/"
                  >
                    Home
                  </NavLink>
                </li>
                <li
                  className="navWrapper__navItems--items"
                  onClick={() => setNavInfo(false)}
                >
                  <NavLink
                    className={(navData) =>
                      navData.isActive ? "activeSidebar" : "notActive"
                    }
                    to="/products"
                  >
                    Products
                  </NavLink>
                </li>
                <li
                  className="navWrapper__navItems--items"
                  onClick={() => setNavInfo(false)}
                >
                  <NavLink
                    className={(navData) =>
                      navData.isActive ? "activeSidebar" : "notActive"
                    }
                    to="/gallery"
                  >
                    Gallery
                  </NavLink>
                </li>
                <li
                  className="navWrapper__navItems--items"
                  onClick={() => setNavInfo(false)}
                >
                  <NavLink
                    className={(navData) =>
                      navData.isActive ? "activeSidebar" : "notActive"
                    }
                    to="/outlets"
                  >
                    Outlets
                  </NavLink>
                </li>
                <li
                  className="navWrapper__navItems--items"
                  onClick={() => setNavInfo(false)}
                >
                  <NavLink
                    className={(navData) =>
                      navData.isActive ? "activeSidebar" : "notActive"
                    }
                    to="/factory"
                  >
                    Factory
                  </NavLink>
                </li>

                <li className="navWrapper__navItems--items">
                  <section role="navDrop">
                    <Dropdown>
                      <Dropdown.Toggle variant="success" id="dropdown-basic">
                        About Us
                      </Dropdown.Toggle>

                      <Dropdown.Menu>
                        <Dropdown.Item onClick={() => setNavInfo(false)}>
                          <NavLink
                            className={(navData) =>
                              navData.isActive ? "activeSidebar" : "notActive"
                            }
                            to="/company-overview"
                          >
                            Company Overview
                          </NavLink>
                        </Dropdown.Item>
                        <Dropdown.Item onClick={() => setNavInfo(false)}>
                          <NavLink
                            className={(navData) =>
                              navData.isActive ? "activeSidebar" : "notActive"
                            }
                            to="/board-profile"
                          >
                            Board Profile
                          </NavLink>
                        </Dropdown.Item>
                        <Dropdown.Item onClick={() => setNavInfo(false)}>
                          <NavLink
                            className={(navData) =>
                              navData.isActive ? "activeSidebar" : "notActive"
                            }
                            to="/news"
                          >
                            Paicho On Media
                          </NavLink>
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </section>
                </li>
                <li
                  className="navWrapper__navItems--items"
                  onClick={() => setNavInfo(false)}
                >
                  <NavLink
                    className={(navData) =>
                      navData.isActive ? "activeSidebar" : "notActive"
                    }
                    to="/contact"
                  >
                    Contact
                  </NavLink>
                </li>
              </ul>
            </section>
          }
        </section>
      </nav>
    </div>
  );
};

export default Navbar;
