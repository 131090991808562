import React from "react";
import { Row } from "react-bootstrap";
import BoardImageSkeleton from "./BoardImageSkeleton";
import BoardMessageSkeleton from "./BoardMessageSkeleton";

const BoardProfileSkeleton = () => {
  return (
    <div className="mt-5 d-flex flex-column gap-5">
      <Row>
        <BoardImageSkeleton />
        <BoardMessageSkeleton />
      </Row>
      <Row>
        <BoardMessageSkeleton />
        <BoardImageSkeleton />
      </Row>
      <Row>
        <BoardImageSkeleton />
        <BoardMessageSkeleton />
      </Row>
    </div>
  );
};

export default BoardProfileSkeleton;
