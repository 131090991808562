import { gql } from "@apollo/client";

export const GET_FACTORY_PAGE = gql`
  query getFactoryPage {
    page(id: "cG9zdDo0MTAx", idType: ID) {
      pageHeaderBlock {
        pageTitle
        pageDescription
        pageBackground {
          sourceUrl
          srcSet
        }
      }
      factory {
        bigHeading
        smallHeading
        featuredImage {
          sourceUrl
          srcSet
        }
        mechanism {
          title
          description
          image {
            sourceUrl
            srcSet
          }
        }
      }
    }
  }
`;
