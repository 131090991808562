import React from "react";
import { Col } from "react-bootstrap";
import Skeleton from "../common/Skeleton";

const ImageSkeleton = () => {
  return (
    <Col lg={6} className="mb-4">
      <Skeleton height="24rem" width="100%" radius="0" />
    </Col>
  );
};

export default ImageSkeleton;
