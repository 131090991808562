import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { GET_AWARDS } from "../../queries/awards/getAwards";
import Query from "../../utils/Query";
import AwardsSkeleton from "./AwardsSkeleton";

const Awards = () => {
  return (
    <div>
      <Container>
        <section className="awardWrapper">
          <div className="preTitle">Our Achievements</div>
          <h2 className="mainTitle">Awards</h2>

          <section className="mt-5">
            {/* <AwardsSkeleton /> */}
            <Row>
              <Query query={GET_AWARDS} loader={<AwardsSkeleton />}>
                {({
                  data: {
                    page: {
                      awards: { awards },
                    },
                  },
                }) => {
                  return awards.map((entry, key) => (
                    <Col md={6} key={key}>
                      <figure className="awardWrapper-image">
                        <img
                          src={entry.sourceUrl}
                          srcSet={entry.srcSet}
                          alt=""
                        />
                      </figure>
                    </Col>
                  ));
                }}
              </Query>
            </Row>
          </section>
        </section>
      </Container>
    </div>
  );
};

export default Awards;
