import { gql } from "@apollo/client";

export const GET_COMPANY_OVERVIEW = gql`
  query getCompanyOverview {
    page(id: "cG9zdDo0MTE2", idType: ID) {
      pageHeaderBlock {
        pageTitle
        pageDescription
        pageBackground {
          sourceUrl
          srcSet
        }
      }
      overviewSection {
        overviewSection {
          overviewTitle
          overviewHeading
          overviewDescription
          overviewFeaturedImage {
            sourceUrl
            srcSet
          }
        }
        overviewMessage {
          heading
          description
          name
          featuredImage {
            sourceUrl
            srcSet
          }
      }
      }
    
      
    }
  }
`;
