import { gql } from "@apollo/client";

export const GET_OUTLETS_HEADER = gql`
  query getOutletHeader {
    page(id: "cG9zdDo0MDg2", idType: ID) {
      pageHeaderBlock {
        pageTitle
        pageDescription
        pageBackground {
          sourceUrl
          srcSet
        }
      }
    }
  }
`;
