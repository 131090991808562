import React from "react";
import { Row } from "react-bootstrap";
import CardSkeleton from "../CategoryCard/CardSkeleton";

const CategoriesSkeleton = () => {
  return (
    <Row>
      {[...Array(5)].map((_, key) => (
        <CardSkeleton key={key} />
      ))}
    </Row>
  );
};

export default CategoriesSkeleton;
