import React from "react";
import { Col } from "react-bootstrap";
import Skeleton from "../common/Skeleton";
import HeroSkeleton from "../HeroComponent/HeroSkeleton";

const MediaCardSkeleton = () => {
  return (
    <div>
      <HeroSkeleton />
      <div className="row mt-4">
        {[...Array(7)].map((_, key) => (
          <Col md={4} key={key} className="mb-4">
            <Skeleton height="17rem" width="100%" radius="0" />
          </Col>
        ))}
      </div>
    </div>
  );
};

export default MediaCardSkeleton;
