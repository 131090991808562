import React from 'react';
import WhyPaicho from '../../components/WhyPaicho';
import HeroSection from '../../components/HeroSection';
import Categories from '../../components/Categories';
import Contribution from '../../components/Contribution';
import Stats from '../../components/Stats';
import HowPaicho from '../../components/HowPaicho';
import Widget from '../../components/Widget';
import Testimonials from '../../components/Testimonials';
import ISOCertified from '../../components/ISOCertified';
import Awards from '../../components/Awards';
import Welcome from '../../components/Welcome';

const Home = () => {
  return (
    <>
      <HeroSection />
      <Welcome />
      <WhyPaicho />
      <Contribution />
      <ISOCertified />
      <Awards />
      <Stats />
      <Categories />
      <HowPaicho />
      <Widget />
      <Testimonials />
    </>
  );
};

export default Home;
