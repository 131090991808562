import moment from "moment";
import React from "react";
import { Row } from "react-bootstrap";

function NewsBox({ link, title, date }) {
  const _date = moment(new Date(date)).format("DD MMMM YYYY");
  return (
    <Row>
      <a href={`${link}`} target="_blank" rel="noopener noreferrer">
        <section className="newsBox">
          <section className="text-muted">
            <i className="fa fa-calendar"></i> &nbsp; {_date}
          </section>
          <section>
            <h3>{title}</h3>
          </section>
        </section>
      </a>
    </Row>
  );
}

export default NewsBox;
