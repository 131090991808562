import { gql } from "@apollo/client";

export const GET_CATEGORIES = gql`
  query getCategories {
    categories(first: 1000) {
      nodes {
        category {
          title
          categoryUrl
          featuredImage {
            srcSet
            sourceUrl
          }
        }
      }
    }
  }
`;
