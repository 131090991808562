import { gql } from "@apollo/client";

export const GET_BOARD_PROFILES_HEADER = gql`
  query getBoardProfileHeader {
    page(id: "cG9zdDo0MTMz", idType: ID) {
      pageHeaderBlock {
        pageTitle
        pageDescription
        pageBackground {
          sourceUrl
          srcSet
        }
      }
    }
  }
`;
