import React from "react";
import { Container } from "react-bootstrap";

function Widget() {
  return (
    <Container>
      <section className="widget">
        <h3>Want to see more paicho products ? Visit our ecommerce shop. </h3>
        <a href="https://shop.paicho.com" target="_blank" rel="noreferrer">
          <button className="bton bton--primary bton--lg">Shop Now</button>
        </a>
      </section>
    </Container>
  );
}

export default Widget;
