import React from "react";

const HeroComponent = ({ title, content, image }) => {
  return (
    <section role="heroComponent">
      <img src={image} className="imageBox" alt="" />

      <section className="content">
        <h1 className="strongUp text-center cpr ">{title}</h1>
        <p>{content}</p>
      </section>
    </section>
  );
};

export default HeroComponent;
