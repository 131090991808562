import React from "react";
import { GET_OFFICES } from "../../queries/offices/getOffices";
import Query from "../../utils/Query";

const Map = () => {
  return (
    <section className="office">
      <h2>Office Locations</h2>
      <hr />
      <section className="office__items">
        <Query query={GET_OFFICES}>
          {({
            data: {
              offices: { nodes },
            },
          }) => {
            return nodes.map(({ officeDetails }, key) => (
              <div className="office__items--box" key={key}>
                <h3 className="office_items_h3">{officeDetails.officeName}</h3>

                <p>{officeDetails.officeAddress}</p>
                {officeDetails.officeContact ? (
                  <p>{officeDetails.officeContact}</p>
                ) : null}
                {officeDetails.officeMail ? (
                  <p>{officeDetails.officeMail}</p>
                ) : null}
              </div>
            ));
          }}
        </Query>
      </section>

      <section className="Mapsection">
        <iframe
          width="100%"
          height="600"
          title="paicho map"
          src="https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=en&amp;q=Baletaksar%20Gulmi+(Paicho%20Pasal)&amp;t=&amp;z=13&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
        >
          <a href="https://www.gps.ie/marine-gps/">boat tracking</a>
        </iframe>
      </section>
    </section>
  );
};

export default Map;
