import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import Skeleton from "../common/Skeleton";
import OutletCardSkeleton from "./OutletCardSkeleton";

const OutletSkeleton = () => {
  return (
    <Container className="mt-5">
      <Row>
        <Col md={3}>
          <div className="mb-4">
            <Skeleton height="4rem" width="100%" />
          </div>
          <Skeleton height="4rem" width="100%" />
        </Col>
        <Col md={9}>
          <div className="mb-4">
            <Skeleton height="17rem" width="100%" />
          </div>
          <OutletCardSkeleton />
        </Col>
      </Row>
    </Container>
  );
};

export default OutletSkeleton;
