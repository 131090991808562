import { gql } from "@apollo/client";

export const GET_NEWS = gql`
  query getNews {
    newses(first: 1000) {
      nodes {
        news {
          date
          title
          url
        }
      }
    }
  }
`;
