import { gql } from "@apollo/client";

export const GET_CONTACT_HEADER = gql`
  query getContactHeader {
    page(id: "cG9zdDo0MTUw", idType: ID) {
      pageHeaderBlock {
        pageTitle
        pageDescription
        pageBackground {
          sourceUrl
          srcSet
        }
      }
    }
  }
`;
