import React from 'react';
import { Col, Row } from 'react-bootstrap';
import LayoutImage from '../../assets/Images/staticImages/freshveggi.png';

function WhyPaicho() {
  return (
    <section className='layoutWrapper1'>
      <Row>
        <Col lg={6}>
          <section className='whyImage1'>
            <img src={LayoutImage} alt='' />
          </section>
        </Col>
        <Col lg={6}>
          <section className='whyContentWrapper1'>
            <section className='whyContent1'>
              <div className='preTitle'>Why paicho</div>
              <h2 className='mainTitle'>Why Choose Paicho?</h2>

              <ul className='whyLIst1'>
                <li className='flex-between whyLIst1__box '>
                  <div>
                    <i className='fa fa-check cpr'></i>
                  </div>
                  <p>
                    High-Altitude Freshness: Our vegetables are grown in
                    pristine, high-altitude regions, resulting in unique flavors
                    and superior quality.
                  </p>
                </li>
                <li className='flex-between whyLIst1__box '>
                  <div>
                    <i className='fa fa-check cpr'></i>
                  </div>
                  <p>
                    Farm-to-Table Transparency: From seed planting to harvest
                    and production, we manage every step to ensure freshness and
                    quality in our products.
                  </p>
                </li>
                <li className='flex-between whyLIst1__box '>
                  <div>
                    <i className='fa fa-check cpr'></i>
                  </div>
                  <p>
                    Diverse Product Range: Enjoy a variety of delectable
                    products, including rich tomato ketchup, tangy pickles, and
                    flavorful jams, all crafted with care.
                  </p>
                </li>
                <li className='flex-between whyLIst1__box '>
                  <div>
                    <i className='fa fa-check cpr'></i>
                  </div>
                  <p>
                    {' '}
                    Support for Farmers: By choosing Paicho, you’re supporting
                    local farmers and contributing to their economic growth and
                    success.
                  </p>
                </li>
                <li className='flex-between whyLIst1__box '>
                  <div>
                    <i className='fa fa-check cpr'></i>
                  </div>
                  <p>
                    {' '}
                    Sustainable Practices: We prioritize eco-friendly practices,
                    ensuring our operations are both environmentally and
                    socially responsible.
                  </p>
                </li>
                <li className='flex-between whyLIst1__box '>
                  <div>
                    <i className='fa fa-check cpr'></i>
                  </div>
                  <p>
                    Quality Assurance: Our products undergo rigorous quality
                    checks to meet high standards and deliver consistently
                    excellent taste.
                  </p>
                </li>
                <li className='flex-between whyLIst1__box '>
                  <div>
                    <i className='fa fa-check cpr'></i>
                  </div>
                  <p>
                    We use advanced technology to enhance production efficiency
                    and maintain top-notch quality in every product.
                  </p>
                </li>
                <li className='flex-between whyLIst1__box '>
                  <div>
                    <i className='fa fa-check cpr'></i>
                  </div>
                  <p>
                    Community Focus: Our mission is to foster not only
                    exceptional products but also to strengthen the
                    communities we work with.
                  </p>
                </li>
              </ul>
            </section>
          </section>
        </Col>
      </Row>
    </section>
  );
}

export default WhyPaicho;
