import React, { useState } from "react";
import { Tab, Col, Container, Row, Nav } from "react-bootstrap";
import HeroComponent from "../../components/HeroComponent";

import OutletCard from "../../components/OutletCard";
import OutletSkeleton from "../../components/OutletCard/OutletSkeleton";
import { GET_OUTLETS_HEADER } from "../../queries/pageHeaders/getOutletHeader";
import Query from "../../utils/Query";
import HeroSkeleton from "../../components/HeroComponent/HeroSkeleton";
import { GET_OUTLETS } from "../../queries/outlets/getOutlets";

const Outlets = () => {
  const [store, setStore] = useState(0);
  return (
    <>
      <Query query={GET_OUTLETS_HEADER} loader={<HeroSkeleton />}>
        {({ data: { page } }) => {
          return (
            <HeroComponent
              image={page.pageHeaderBlock.pageBackground.sourceUrl}
              title={page.pageHeaderBlock.pageTitle}
              content={page.pageHeaderBlock.pageDescription}
            />
          );
        }}
      </Query>

      <Query query={GET_OUTLETS} loader={<OutletSkeleton />}>
        {({
          data: {
            outlets: { nodes },
          },
        }) => {
          return nodes.length > 0 ? (
            <section role="outletsTab">
              <Container>
                <Tab.Container
                  id="left-tabs-example"
                  defaultActiveKey={nodes[0].slug}
                >
                  <Row>
                    <Col md={3}>
                      <Nav variant="pills" className="flex-column">
                        {nodes.map((entry, key) => (
                          <Nav.Item key={entry.slug}>
                            <Nav.Link
                              eventKey={entry.slug}
                              onClick={(e) => {
                                e.preventDefault();
                                setStore(key);
                              }}
                            >
                              {entry.stores.title}
                            </Nav.Link>
                          </Nav.Item>
                        ))}
                      </Nav>
                    </Col>
                    <Col md={9}>
                      <section className="outletBox">
                        <Row>
                          <Col md={12}>
                            <section className="outletBox-image">
                              <img
                                src={
                                  nodes[store].stores?.featuredImage?.sourceUrl
                                }
                                srcSet={
                                  nodes[store]?.stores?.featuredImage?.srcSet
                                }
                                alt=""
                              />
                            </section>
                          </Col>

                          {nodes[store]?.stores?.storeDetails?.map(
                            (entry, key) => (
                              <OutletCard
                                key={key}
                                title={entry.storeName}
                                phone={entry.phone}
                                name={entry.storeManager}
                                location={entry.address}
                              />
                            )
                          )}
                        </Row>
                      </section>
                    </Col>
                  </Row>
                </Tab.Container>
              </Container>
            </section>
          ) : (
            <p className="text-center w-100 mt-5">No Outlets Found</p>
          );
        }}
      </Query>
    </>
  );
};

export default Outlets;
