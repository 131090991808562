import React from "react";
import { Container, Row } from "react-bootstrap";
import CategoryCard from "../CategoryCard";
import CategoriesSkeleton from "./CategoriesSkeleton";
import Query from "../../utils/Query";
import { GET_CATEGORIES } from "../../queries/categories/getCategories";

function Categories() {
  return (
    <section className="categoriesWrapper">
      <Container>
        <Row>
          <div className="preTitle">Our items</div>
          <h2 className="mainTitle">Paicho Categories</h2>
          <p className="w-50">
            We have been serving a wide rang of our products which will be used
            by HORECA as well as household.
          </p>
        </Row>
        <Query query={GET_CATEGORIES} loader={<CategoriesSkeleton />}>
          {({
            data: {
              categories: { nodes },
            },
          }) => {
            return (
              <Row>
                {nodes.map(({ category }, key) => (
                  <CategoryCard
                    link={category?.categoryUrl}
                    name={category?.title}
                    image={category?.featuredImage}
                    key={key}
                  />
                ))}
              </Row>
            );
          }}
        </Query>
      </Container>
    </section>
  );
}

export default Categories;
