import React from "react";
import Skeleton from "../common/Skeleton";

const NewsSkeleton = () => {
  return (
    <section>
      {[...Array(6)].map((_, key) => (
        <article className="newsBox" key={key}>
          <div className="text-muted d-flex skeleton-header">
            <i className="fa fa-calendar"></i>
            <Skeleton height="1.2rem" width="100%" margin="0 0 1rem 1rem" />
          </div>
          <div className="skeleton-content">
            <Skeleton height="2.5rem" width="100%" />
          </div>
        </article>
      ))}
    </section>
  );
};

export default NewsSkeleton;
