import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import Market from '../../assets/Images/market.png';
import Farmer from '../../assets/Images/farmer.png';
import FarmerMarket from '../../assets/Images/farmertomarket.png';
import MarketFarmer from '../../assets/Images/markettpfarmer.png';

function HowPaicho() {
  return (
    <Container>
      <section className='howPaichoWrapper'>
        <div className='preTitle'>working</div>
        <h2 className='mainTitle'>How Paicho Works</h2>
        <p className='w-50 mb-5'>
          The company has innovative business model, providing direct benefit to
          small and medium farmers, local businessman and other agents
          whichultimatelycontributes to economic of the country.
        </p>

        <Row>
          <Col md={7}>
            <section className='farmer'>
              <h3 className='cse'>Farmer's Production To Market</h3>
              <p>
                We have 60+ collection centers for farmers to drop their
                produce. They can choose to either to collect their money or buy
                some goods for their household. That is why we call it “Paicho”
                which means “Barter”. We transport these collected goods to the
                Butwal centre within 18 hours and so that the consumers get
                fresh vegetables. Our focus areas are Ghulmi and Butwal
                nevertheless we have 13 shops in the hilly areas out of which 3
                run as complete departmental store. The department stores sell
                only our products but the stores in rural parts have all kinds
                of goods including grocery, home appliance and other products
              </p>
            </section>
          </Col>

          <Col md={5} className='order-md-last order-first'>
            <section className='farmerImage'>
              <img src={Market} alt='' />
            </section>
          </Col>
        </Row>

        <Row>
          <section className='farmerToMarket'>
            <img src={FarmerMarket} alt='' />
          </section>
        </Row>

        <Row>
          <Col md={7}>
            <section className='farmer  '>
              <h3 className='cse'>Market Goods to Farmers</h3>
              <p>
                Hundreds of farmers have been taking benefits of the company
                services and facilities. The company is able to reach
                approximately 30,000+ farmers in Gulmi, Palpa and Arghachanchi.
                They are directly A total of 75 million rupees has been
                invested. The company becomes the largest exporter of vegetables
                and fruits in Gulmi district. We are being successful in a
                country where farmers were unable to take their agricultural
                products to the market because of various constraints. We also
                hope to serve more consumers aswell as farmers.
              </p>
            </section>
          </Col>

          <Col md={5} className='order-md-last order-first'>
            <section className='farmerImage'>
              <img src={Farmer} alt='' />
            </section>
          </Col>
        </Row>

        <Row>
          <section className='farmerToMarket'>
            <img src={MarketFarmer} alt='' />
          </section>
        </Row>
      </section>
    </Container>
  );
}

export default HowPaicho;
