import { gql } from "@apollo/client";

export const GET_STATISTICS = gql`
  query getStatistics {
    page(id: "cG9zdDo0MDM0", idType: ID) {
      statistics {
        statisticsItems {
          statName
          statValue
        }
      }
    }
  }
`;
