import React, { useRef, useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";

import { Col, Form, Row } from "react-bootstrap";
import axios from "axios";

const ContactForm = () => {
  const captchaRef = useRef();

  const [validated, setValidated] = useState(false);
  const [verified, setVerified] = useState(false);
  const [contact, setContact] = useState({
    full_name: "",
    email: "",
    phone: "",
    message: "",
  });
  const [submitStatus, setSubmitStatus] = useState("idle");

  const handleOnChange = (e) => {
    if (e) {
      setVerified(true);
    } else {
      setVerified(false);
    }
  };
  const onChange = (e) =>
    setContact({ ...contact, [e.target.name]: e.target.value });

  const on_submit = async (e) => {
    e.preventDefault();
    const form = e.currentTarget;

    setValidated(true);

    if (form.checkValidity() === true) {
      setValidated(true);
      setSubmitStatus("loading");

      await axios
        .post("https://paicho-mail.herokuapp.com/api/mail/message", contact)
        .then(() => {
          setSubmitStatus("success");
          setTimeout(() => {
            setSubmitStatus("idle");

            setContact({
              full_name: "",
              email: "",
              phone: "",
              message: "",
            });
            captchaRef.current.reset();
          }, 3000);

          setValidated(false);
        })
        .catch((err) => {
          setSubmitStatus("error");
          setTimeout(() => {
            setSubmitStatus("idle");
          }, 3000);
        });
    }
  };
  return (
    <section className="formWrapper">
      <h2>Let's talk</h2>
      <hr />
      <Form noValidate validated={validated} onSubmit={on_submit}>
        <Row>
          <Col md={6}>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Full Name</Form.Label>
              <Form.Control
                value={contact.full_name}
                type="text"
                placeholder="your name"
                name="full_name"
                onChange={onChange}
                required
              />
            </Form.Group>
          </Col>
          <Col md={6}>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Phone Number</Form.Label>
              <Form.Control
                type="text"
                value={contact.phone}
                name="phone"
                onChange={onChange}
                placeholder="phone number"
                required
              />
            </Form.Group>
          </Col>
          <Col md={12}>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Email</Form.Label>
              <Form.Control
                type="email"
                placeholder="name@example.com"
                value={contact.email}
                name="email"
                onChange={onChange}
                required
              />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group
              className="mb-3"
              controlId="exampleForm.ControlTextarea1"
            >
              <Form.Label>Message</Form.Label>
              <Form.Control
                as="textarea"
                value={contact.message}
                name="message"
                onChange={onChange}
                rows={3}
                placeholder="your message here"
                required
              />
            </Form.Group>
          </Col>
        </Row>
        <ReCAPTCHA
          sitekey={process.env.REACT_APP_GOOGLE_SITE_KEY}
          onChange={handleOnChange}
          ref={captchaRef}
        />
        <button
          type="submit"
          className="bton bton--lg bton--primary"
          disabled={!verified}
        >
          {submitStatus === "loading"
            ? "Loading..."
            : submitStatus === "success"
            ? "Thankyou"
            : submitStatus === "error"
            ? "Error"
            : "Submit"}
        </button>
      </Form>
    </section>
  );
};

export default ContactForm;
