import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import HeroComponent from "../../components/HeroComponent";
import Stats from "../../components/Stats";
import Awards from "../../components/Awards";
import { GET_COMPANY_OVERVIEW } from "../../queries/companyOverview/getCompanyOverview";
import Query from "../../utils/Query";
import CompanyOverviewSkeleton from "../../components/companyOverview/CompanyOverviewSkeleton";

const CompanyOverview = () => {
  return (
    <Query query={GET_COMPANY_OVERVIEW} loader={<CompanyOverviewSkeleton />}>
      {({ data: { page } }) => {
        return (
          <>
            <HeroComponent
              image={page.pageHeaderBlock.pageBackground.sourceUrl}
              title={page.pageHeaderBlock.pageTitle}
              content={page.pageHeaderBlock.pageDescription}
            />

            {page.overviewSection.overviewSection.map((entry, key) => (
              <section className="layoutWrapper2" key={key}>
                <Row>
                  <Col lg={6}>
                    <section className="whyImage2">
                      <img
                        src={entry.overviewFeaturedImage.sourceUrl}
                        srcSet={entry.overviewFeaturedImage.srcSet}
                        alt=""
                      />
                    </section>
                  </Col>
                  <Col lg={6}>
                    <div className="whyContent2">
                      <span className="preTitle">{entry.overviewHeading}</span>
                      <h2 className="mainTitle">{entry.overviewTitle}</h2>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: entry.overviewDescription,
                        }}
                      />
                    </div>
                  </Col>
                </Row>
              </section>
            ))}

            <Stats />

            <section className="chairmanWrapper">
              <Container>
                <Row>
                  <Col lg={3}>
                    <figure className="chairmanWrapper-image">
                      <img
                        src={page.overviewSection.overviewMessage.featuredImage.sourceUrl}
                        srcSet={page.overviewSection.overviewMessage.featuredImage.srcSet}
                        alt=""
                      />
                      <figcaption>{page.overviewSection.overviewMessage.name}</figcaption>
                    </figure>
                  </Col>

                  <Col>
                    <section className="chairmanWrapper-content">
                      <h2 className="cse">{page.overviewSection.overviewMessage.heading}</h2>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: page.overviewSection.overviewMessage.description,
                        }}
                      />
                    </section>
                  </Col>
                </Row>
              </Container>
            </section>

            <Awards />
          </>
        );
      }}
    </Query>
  );
};

export default CompanyOverview;
