import Skeleton from "../common/Skeleton";

const StatSkeleton = () => {
  return (
    <section className="row py-4">
      {[...Array(4)].map((_, key) => (
        <div key={key} className="col-md-3">
          <Skeleton height="5rem" width="90%" margin="auto" />
          <Skeleton height="2rem" width="80%" margin="1rem auto" />
        </div>
      ))}
    </section>
  );
};

export default StatSkeleton;
