import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import HeroComponent from "../../components/HeroComponent";
import BoardProfileSkeleton from "../../components/boardProfile/BoardProfileSkeleton";
import { GET_BOARD_PROFILES_HEADER } from "../../queries/pageHeaders/getBoardProfileHeader";
import Query from "../../utils/Query";
import HeroSkeleton from "../../components/HeroComponent/HeroSkeleton";
import { GET_BOARD_PROFILES } from "../../queries/boardProfile/getBoardProfiles";

const BoardProfile = () => {
  return (
    <>
      <Query query={GET_BOARD_PROFILES_HEADER} loader={<HeroSkeleton />}>
        {({ data: { page } }) => {
          return (
            <HeroComponent
              image={page.pageHeaderBlock.pageBackground.sourceUrl}
              title={page.pageHeaderBlock.pageTitle}
              content={page.pageHeaderBlock.pageDescription}
            />
          );
        }}
      </Query>

      <Container>
        <Query query={GET_BOARD_PROFILES} loader={<BoardProfileSkeleton />}>
          {({
            data: {
              boardProfiles: { nodes },
            },
          }) => {
            return nodes.map(({ boardProfile }, key) => (
              <div className="boardWrapper">
                <Row key={key}>
                  <Col lg={4}>
                    <figure className="boardWrapper-image">
                      <img
                        src={boardProfile.photo.sourceUrl}
                        srcSet={boardProfile.photo.srcSet}
                        alt=""
                      />
                      <figcaption className="boardWrapper-image-post">
                        {boardProfile.designation}
                      </figcaption>
                      <figcaption className="boardWrapper-image-name">
                        {boardProfile.name}
                      </figcaption>
                    </figure>
                  </Col>
                  <Col lg={8}>
                    <div
                      className="boardWrapper-content"
                      dangerouslySetInnerHTML={{
                        __html: boardProfile.message,
                      }}
                    />
                  </Col>
                </Row>
              </div>
            ));
          }}
        </Query>
      </Container>
    </>
  );
};

export default BoardProfile;
