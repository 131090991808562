import React from "react";
import { Container } from "react-bootstrap";
import HeroComponent from "../../components/HeroComponent";
import NewsBox from "../../components/NewsBox";
import NewsSkeleton from "../../components/NewsBox/NewsSkeleton";
import Query from "../../utils/Query";
import { GET_NEWS } from "../../queries/news/getNews";
import { GET_NEWS_HEADER } from "../../queries/pageHeaders/getNewsHeader";
import HeroSkeleton from "../../components/HeroComponent/HeroSkeleton";

const News = () => {
  return (
    <div>
      <Query query={GET_NEWS_HEADER} loader={<HeroSkeleton />}>
        {({ data: { page } }) => {
          return (
            <HeroComponent
              image={page.pageHeaderBlock.pageBackground.sourceUrl}
              title={page.pageHeaderBlock.pageTitle}
              content={page.pageHeaderBlock.pageDescription}
            />
          );
        }}
      </Query>

      <section className="newsWrapper">
        <Container>
          <h2>News</h2>
          <Query query={GET_NEWS} loader={<NewsSkeleton />}>
            {({
              data: {
                newses: { nodes },
              },
            }) => {
              return nodes.map(({ news }, key) => (
                <NewsBox
                  key={key}
                  date={news.date}
                  title={news.title}
                  link={news.url}
                />
              ));
            }}
          </Query>
        </Container>
      </section>
    </div>
  );
};

export default News;
