import { gql } from "@apollo/client";

export const GET_AWARDS = gql`
  query getAwards {
    page(id: "cG9zdDo0MDEz", idType: ID) {
      awards {
        awards {
          sourceUrl
          srcSet
        }
      }
    }
  }
`;
