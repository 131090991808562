import { gql } from "@apollo/client";

export const GET_HOME_HEADER = gql`
  query getHomeHeader {
    page(id: "cG9zdDo0MDI0", idType: ID) {
      homeHeader {
        headerimage {
          sourceUrl
          srcSet
        }
        headertitle
        headerdescription
        backgroundvideo {
          mediaItemUrl
        }
      }
    }
  }
`;
