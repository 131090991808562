import { gql } from "@apollo/client";

export const GET_ALL_PRODUCTS = gql`
  query getAllProducts {
    categories(first: 1000) {
      nodes {
        slug
        category {
          title
          products {
            productName
            productImage {
              sourceUrl
              srcSet
            }
            productUrl
          }
        }
      }
    }
  }
`;
