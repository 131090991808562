import React from "react";
import { Col } from "react-bootstrap";
import Skeleton from "../common/Skeleton";

const BoardMessageSkeleton = () => {
  return (
    <Col lg={8}>
      <div className="skeleton-para">
        <Skeleton height="1.2rem" width="100%" />
        <Skeleton height="1.2rem" width="98%" />
        <Skeleton height="1.2rem" width="96%" />
        <Skeleton height="1.2rem" width="99%" />
        <Skeleton height="1.2rem" width="90%" />
        <Skeleton height="1.2rem" width="50%" />
        <Skeleton height="1.2rem" width="100%" />
        <Skeleton height="1.2rem" width="98%" />
        <Skeleton height="1.2rem" width="99%" />
        <Skeleton height="1.2rem" width="60%" />
      </div>
    </Col>
  );
};

export default BoardMessageSkeleton;
