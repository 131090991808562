import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const Footer = () => {
  return (
    <>
      <section role='Footer'>
        <Container>
          <Row className='justify-content-between'>
            <Col md={6} lg={3}>
              <section className='foooterInfo'>
                <header className='Links-title'>
                  <h3>About Paicho</h3>
                </header>
                <p>
                  Paicho Pasal Private Limited was established in 2071. Main
                  goal of the PaichoPasal is to best utilize the products of
                  farmers, support them to product organic agricultural
                  products, promote markets of farmers' products and provide
                  them the direct benefits in value chain basis.
                </p>
              </section>
            </Col>
            <Col md={6} lg={2}>
              <section className='Links'>
                <header className='Links-title'>
                  <h3>Links</h3>
                </header>

                <ul className='Links-box'>
                  <li className='Links-box-item'>
                    <Link to='/company-overview'>About Us</Link>
                  </li>
                  <li className='Links-box-item'>
                    <Link to='/products'>Products</Link>
                  </li>
                  <li className='Links-box-item'>
                    <Link to='/outlets'>Outlets</Link>
                  </li>
                  <li className='Links-box-item'>
                    <Link to='/contact'>Contact Us</Link>
                  </li>
                </ul>
              </section>
            </Col>
            <Col md={6} lg={3}>
              <section className='contact'>
                <header className='contact-title'>
                  <h3>Contact</h3>
                </header>

                <ul className='contact-box'>
                  <li className='contact-box-item'>
                    <div className='iconHolder'>
                      <i className='fa fa-map-marker'></i>
                    </div>
                    <div className='addHolder'>
                      <div className='addHolder-key'>
                        <strong>Location</strong>
                      </div>
                      <div className='addHolder-value '>Sukhanagar, Butwal</div>
                    </div>
                  </li>
                  <li className='contact-box-item'>
                    <div className='iconHolder'>
                      <i className='fa fa-phone'></i>
                    </div>
                    <div className='addHolder'>
                      <div className='addHolder-key'>
                        <strong>Phone</strong>
                      </div>
                      <div className='addHolder-value '>
                        079410069, 9857076080 <br /> Toll Free no: 16607952111
                      </div>
                    </div>
                  </li>

                  <li className='contact-box-item'>
                    <div className='iconHolder'>
                      <i className='fa fa-envelope'></i>
                    </div>
                    <div className='addHolder'>
                      <div className='addHolder-key'>
                        <strong>Email</strong>
                      </div>
                      <div className='addHolder-value '>
                        <a
                          href='mailto:paichopasal@gmail.com'
                          style={{ color: 'white' }}
                        >
                          paichopasal@gmail.com
                        </a>
                      </div>
                    </div>
                  </li>
                </ul>
              </section>
            </Col>
            <Col md={6} lg={3}>
              <section className='socialMedia'>
                <header className='socialMedia-title'>
                  <h3>Social Links</h3>
                </header>

                <section className='socialWrapper'>
                  <a
                    href={'https://www.facebook.com/paichopasal'}
                    target='_blank'
                    rel='noreferrer noopener'
                  >
                    <div className='socialWrapper-icons'>
                      <i className='fa fa-facebook'></i>
                    </div>
                  </a>

                  <a
                    href={'https://www.instagram.com/paicho_pasal/'}
                    target='_blank'
                    rel='noreferrer noopener'
                  >
                    <div className='socialWrapper-icons'>
                      <i className='fa fa-instagram'></i>
                    </div>
                  </a>
                  <a
                    href={'https://twitter.com/paichopasal'}
                    target='_blank'
                    rel='noreferrer noopener'
                  >
                    <div className='socialWrapper-icons'>
                      <i className='fa fa-twitter'></i>
                    </div>
                  </a>
                </section>
              </section>
            </Col>
          </Row>

          <hr />
          <section className='copyright'>
            Paicho &copy; {new Date().getFullYear()} All Rights Reserved
          </section>
        </Container>
      </section>
    </>
  );
};

export default Footer;
