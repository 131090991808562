import React from "react";
import { Col } from "react-bootstrap";
import Skeleton from "../common/Skeleton";

const BoardImageSkeleton = () => {
  return (
    <Col lg={4}>
      <Skeleton height="20rem" width="100%" />
    </Col>
  );
};

export default BoardImageSkeleton;
