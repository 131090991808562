import React from "react";
import { Col } from "react-bootstrap";

function CategoryCard({ name, image, link }) {
  return (
    <Col xlg={3} lg={4} md={6}>
      <section className="categoryCard">
        <figure className="categoryCard__image">
          <img src={image?.sourceUrl} srcSet={image?.srcSet} alt="" />
          <figcaption>{name}</figcaption>
        </figure>

        <a href={`${link}`} target="_new">
          <button className="bton bton--full bton--primary">Shop Now</button>
        </a>
      </section>
    </Col>
  );
}

export default CategoryCard;
