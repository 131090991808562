import { gql } from "@apollo/client";

export const GET_OFFICES = gql`
  query getOffices {
    offices(first: 1000) {
      nodes {
        officeDetails {
          officeName
          officeAddress
          officeContact
          officeMail
        }
      }
    }
  }
`;
